import { Component } from "solid-js";

import hljs from "highlight.js";

const JsonViewer: Component = (props: any) => {
  const highlightedJson = hljs.highlight(JSON.stringify(props.data, null, 2), { language: "json" }).value;

  return (
    <pre class="hljs p-4">
      <code innerHTML={highlightedJson}></code>
    </pre>
  );
};

export default JsonViewer;
