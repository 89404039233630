/* @refresh reload */
import { ErrorBoundary, render } from "solid-js/web";

import "./index.css";
import "./sentry";
import { Route, Router } from "@solidjs/router";
import RedirectMiddleware from "./middleware/redirection";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import NotFound from "./pages/NotFound";
import Premium from "./pages/Premium";
import Terms from "./pages/legal/Terms";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Privacy from "./pages/legal/Privacy";
import Home from "./pages/Home";
import GuildsSelector from "./pages/dashboard/guilds/GuildsSelector";
import GuildInformations from "./pages/dashboard/guilds/GuildInformations";
import { DashboardMenu } from "./components/DashboardMenu";
import ServerList from "./pages/dashboard/guilds/servers/ServersSelector";
import GuildLinks from "./pages/dashboard/guilds/GuildLinks";
import DashboardMiddleware from "./middleware/DashboardMiddleware";
import GuildVerification from "./pages/dashboard/guilds/GuildVerifications";
import ServerInformations from "./pages/dashboard/guilds/servers/ServerInformations";
import ServerStatus from "./pages/dashboard/guilds/servers/ServerStatus";
import ServerPlayer from "./pages/dashboard/guilds/servers/ServerPlayers";
import ServerRoles from "./pages/dashboard/guilds/servers/ServerRoles";
import ServerChats from "./pages/dashboard/guilds/servers/ServerChats";
import ServerPseudo from "./pages/dashboard/guilds/servers/ServerPseudo";
import ServerScreenshots from "./pages/dashboard/guilds/servers/ServerScreenshots";
import Account from "./pages/Account";
import GuildAutoRole from "./pages/dashboard/guilds/GuildAutoRoles";
import Servers from "./pages/Servers";
import ServerVote from "./pages/dashboard/guilds/servers/ServerVote";
import ServerLogs from "./pages/dashboard/guilds/servers/ServerLogs";
import ServerErrors from "./pages/dashboard/guilds/servers/ServerErrors";
import { AdminMenu } from "./components/AdminMenu";
import GuildList from "./pages/dashboard/admins/GuildList";
import GuildBot from "./pages/dashboard/guilds/GuildBot";
import { Component, createSignal, For } from "solid-js";
import Notifications from "./pages/Notifications";
import ServerBugs from "./pages/dashboard/guilds/servers/ServerBugs";
import { I18nProvider } from "./i18n";
import AdminInformations from "./pages/dashboard/admins/AdminInformations";
import ServerTeam from "./pages/dashboard/guilds/servers/ServerTeam";
import "highlight.js/styles/tokyo-night-dark.css";

const root = document.getElementById("root");

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    "Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?",
  );
}

const App: Component = (props: any) => {
  return (
    <I18nProvider>
      <InnerApp props={props} />
    </I18nProvider>
  );
};

// Separate the inner logic into a different component to ensure it's inside the provider
const InnerApp: Component = ({ props }: any) => {
  return (
    <>
      <RedirectMiddleware />
      <Header />
      <div class="flex-grow flex-shrink-0 flex-auto flex flex-col">{props.children}</div>
      <Footer />
    </>
  );
};

const [errorsList, setErrorsList] = createSignal([]);
export const AddError = (error: string, displayTime = 5000) => {
  setErrorsList((errors) => [...errors, error]);
  setTimeout(() => {
    setErrorsList((errors) => errors.filter((e) => e !== error));
  }, displayTime);
};

const AddErrorComponent: Component = (props: any) => {
  return (
    <>
      <div class="text-error flex h-12 items-center rounded-lg border-error border p-4 gap-4">
        <i class="fa-regular fa-circle-xmark"></i>
        <span>Error : {props.message}</span>
      </div>
    </>
  );
};

export const ShowErrorList: Component = () => {
  return (
    <>
      <For each={errorsList()}>{(error) => <AddErrorComponent message={error} />}</For>
    </>
  );
};

const AppDashboard = (props: any) => (
  <>
    <DashboardMiddleware />
    <div class="flex-grow flex-shrink-0 flex-auto flex h-full w-full">
      <DashboardMenu />
      <div class="flex flex-col w-full max-w-screen-2xl mx-auto p-4 gap-4">
        <ShowErrorList />

        <ErrorBoundary
          fallback={(err, reset) => (
            <>
              <AddErrorComponent message={err.message} />
              {props.children}
            </>
          )}
        >
          {props.children}
        </ErrorBoundary>
      </div>
    </div>
  </>
);

const AdminDashboard = (props: any) => (
  <>
    <div class="flex-grow flex-shrink-0 flex-auto flex h-full w-full">
      <AdminMenu />
      <div class="flex flex-col w-full max-w-screen-2xl mx-auto p-4 gap-4">
        <ErrorBoundary
          fallback={(err, reset) => (
            <>
              <div class="text-error flex h-12 items-center rounded-lg border-error border p-4 gap-4">
                <i class="fa-regular fa-circle-xmark"></i>
                <span>Error : {err.message}</span>
              </div>
              {props.children}
            </>
          )}
        >
          {props.children}
        </ErrorBoundary>
      </div>
    </div>
  </>
);

render(
  () => (
    <Router root={App}>
      <Route path="/" component={Home} />
      <Route path="/premium" component={Premium} />
      <Route path="/account" component={Account} />
      <Route path="/legal/privacy" component={Privacy} />
      <Route path="/legal/terms" component={Terms} />
      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route path="/servers" component={Servers} />
      <Route path="/notifications" component={Notifications} />
      <Route path="/dashboard/admins" component={AdminDashboard}>
        <Route path="/informations" component={AdminInformations} />
        <Route path="/guilds" component={GuildList} />
        <Route path="*" component={NotFound} />
      </Route>
      <Route path="/dashboard/guilds" component={GuildsSelector} />
      <Route path="/dashboard/guilds/:guildID" component={AppDashboard}>
        <Route path="/config" component={GuildInformations} />
        <Route path="/config/bot" component={GuildBot} />
        <Route path="/config/links" component={GuildLinks} />
        <Route path="/config/verification" component={GuildVerification} />
        <Route path="/config/auto-role" component={GuildAutoRole} />
        <Route path="/config/servers" component={ServerList} />
        <Route path="/config/servers/:serverID" component={ServerInformations} />
        <Route path="/config/servers/:serverID/status" component={ServerStatus} />
        <Route path="/config/servers/:serverID/players" component={ServerPlayer} />
        <Route path="/config/servers/:serverID/votes" component={ServerVote} />
        <Route path="/config/servers/:serverID/roles" component={ServerRoles} />
        <Route path="/config/servers/:serverID/teams" component={ServerTeam} />
        <Route path="/config/servers/:serverID/chats" component={ServerChats} />
        <Route path="/config/servers/:serverID/logs" component={ServerLogs} />
        <Route path="/config/servers/:serverID/errors" component={ServerErrors} />
        <Route path="/config/servers/:serverID/pseudo" component={ServerPseudo} />
        <Route path="/config/servers/:serverID/screenshots" component={ServerScreenshots} />
        <Route path="/config/servers/:serverID/bugs" component={ServerBugs} />
        <Route path="*" component={NotFound} />
      </Route>
      <Route path="*" component={NotFound} />
    </Router>
  ),
  root!,
);
