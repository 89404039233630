import { Component, createEffect, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI, getUrlWithActualParams, getWSUrl } from "../../../../utils";
import AdminChannelSelector from "../../../../components/AdminChannelSelector";
import { guildChannelsRefetch } from "../GuildInformations";
import { useI18n } from "../../../../i18n";
import DiscordChannel from "../../../../components/discord/DiscordChannel";
import JsonViewer from "../../../../components/JsonViewer";

const fetchLogs = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchLogHideIP = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_hide_ip", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchLogFile = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_include_file", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchLogsChannel = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs/channels", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

interface AddLog {
  data: Object;
  category: string;
  createAt: Date;
}

const typeFormat = {
  playerName: {
    class: "text-yellow-400",
  },
  stringPrimary: {
    class: "text-teal-200",
  },
};

const GetFormat: Component = (props: Object) => {
  return (
    <>
      <span class={typeFormat[props.type].class}>{props.text}</span>
    </>
  );
};

const PlayerComponent: Component = (props: Object) => {
  const { t } = useI18n();
  return (
    <>
      {/* name (orange) */}
      <GetFormat type="playerName" text={props.ply ? props.ply.name : t("dashboard.server.logs.unknown", "Unknown")} />
    </>
  );
};

const logColors: any = {
  player_connect: "#cd8f51",
  player_disconnect: "#cd8f51",
  player_death: "#cd5151",
  player_spawn: "#51cd51",
  player_ready: "#51cd51",
  player_change_team: "#cd51bc",
  player_change_group: "#cd51bc",
  player_change_name: "#cd51bc",
  player_give: "#cdc751",
  player_spawn_object: "#68c13c",
  player_hurt: "#cd5151",
  player_initial_spawn: "#51cd51",
  player_say: "#51c3cd",
  server_start: "#6575d5",
  server_stop: "#6575d5",
};

const logNames: any = {
  player_connect: "Player Connect",
  player_disconnect: "Player Disconnect",
  player_death: "Player Death",
  player_spawn: "Player Spawn",
  player_ready: "Player Ready",
  player_change_team: "Player Change Team",
  player_change_group: "Player Change Group",
  player_change_name: "Player Change Name",
  player_say: "Player Say",
  player_give: "Player Give",
  player_spawn_object: "Player Spawn Object",
  player_hurt: "Player Hurt",
  player_initial_spawn: "Player Initial Spawn",
  server_start: "Server Start",
  server_stop: "Server Stop",
};

const [selectLog, setSelectLog] = createSignal(0);
let idxLog = 0;
const AddLogComponent: Component<AddLog> = (props) => {
  const logContentStr = JSON.stringify(props.data, null, 2).split("\n");
  const { t } = useI18n();
  idxLog++;
  const localIdxLog = idxLog;
  return (
    <>
      <tr>
        <td class="text-gray-300">{props.createAt.toLocaleString()}</td>
        <td class="text-white" style={`color: ${logColors[props.category] || "#fff"}`}>
          {t(`dashboard.server.logs.logNames.${props.category}`, logNames[props.category] || props.category)}
        </td>
        <td>
          <Switch fallback={<span>{JSON.stringify(props.data)}</span>}>
            <Match when={props.category === "player_say"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> {t("dashboard.server.logs.player_say.say", "say")}{" "}
                <GetFormat type="stringPrimary" text={props.data.text} />
              </span>
            </Match>
            <Match when={props.category === "player_disconnect"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t("dashboard.server.logs.player_disconnect.has_disconnected", "has disconnected")}
              </span>
            </Match>
            <Match when={props.category === "player_spawn"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t("dashboard.server.logs.player_spawn.has_spawned", "has spawned")}
              </span>
            </Match>
            <Match when={props.category === "player_death"}>
              <span>
                <PlayerComponent ply={props.data.plyTarget} />{" "}
                {t("dashboard.server.logs.player_death.has_been_killed_by", "has been killed by")}{" "}
                <PlayerComponent ply={props.data.plyAttacker} />
              </span>
            </Match>
            <Match when={props.category === "player_ready"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> {t("dashboard.server.logs.player_ready.is_ready", "is ready")}
              </span>
            </Match>
            <Match when={props.category === "player_connect"}>
              <span>
                <GetFormat type="playerName" text={props.data.name} />{" "}
                {t("dashboard.server.logs.player_connect.is_connecting_from", "is connecting from")}{" "}
                <GetFormat type="stringPrimary" text={props.data.ip} />
              </span>
            </Match>
            <Match when={props.category === "player_change_team"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t("dashboard.server.logs.player_change_team.has_changed_team_from", "has changed team from")}{" "}
                <GetFormat type="stringPrimary" text={props.data.oldTeam} />{" "}
                {t("dashboard.server.logs.player_change_team.to", "to")}{" "}
                <GetFormat type="stringPrimary" text={props.data.newTeam} />
              </span>
            </Match>
            <Match when={props.category === "player_change_group"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t("dashboard.server.logs.player_change_group.has_changed_group_from", "has changed group from")}{" "}
                <GetFormat type="stringPrimary" text={props.data.oldGroup} />{" "}
                {t("dashboard.server.logs.player_change_group.to", "to")}{" "}
                <GetFormat type="stringPrimary" text={props.data.newGroup} />
              </span>
            </Match>
            <Match when={props.category === "player_change_name"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t("dashboard.server.logs.player_change_name.has_changed_name_from", "has changed name from")}{" "}
                <GetFormat type="stringPrimary" text={props.data.oldName} />{" "}
                {t("dashboard.server.logs.player_change_name.to", "to")}{" "}
                <GetFormat type="stringPrimary" text={props.data.newName} />
              </span>
            </Match>
            <Match when={props.category === "player_give"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> {t("dashboard.server.logs.player_give.get", "get")}{" "}
                <GetFormat type="stringPrimary" text={props.data.wep_class} />
              </span>
            </Match>
            <Match when={props.category === "player_spawn_object"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t("dashboard.server.logs.player_spawn_object.has_spawned", "has spawned")}
                <GetFormat type="stringPrimary" text={" " + props.data.object + " "} />(
                <Show
                  when={props.data.object === "prop"}
                  fallback={<GetFormat type="stringPrimary" text={props.data.entity ? props.data.entity.class : ""} />}
                >
                  <GetFormat type="stringPrimary" text={props.data.model} />
                </Show>
                )
              </span>
            </Match>
            <Match when={props.category === "player_hurt"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t("dashboard.server.logs.player_hurt.has_been_hurt_by", "has been hurt by")}{" "}
                <PlayerComponent ply={props.data.attacker} /> {t("dashboard.server.logs.player_hurt.for", "for")}{" "}
                <GetFormat type="stringPrimary" text={props.data.damage} />{" "}
                {t("dashboard.server.logs.player_hurt.damage", "damage")}
              </span>
            </Match>
            <Match when={props.category === "player_initial_spawn"}>
              <span>
                <PlayerComponent ply={props.data.ply} />{" "}
                {t(
                  "dashboard.server.logs.player_initial_spawn.has_spawned_first_time",
                  "has spawned for the first time",
                )}
              </span>
            </Match>
            <Match when={props.category === "server_start" || props.category === "server_stop"}>
              <></>
            </Match>
          </Switch>
        </td>
        <td>
          <div class="flex gap-2 justify-center">
            <div
              class="tooltip tooltip-info"
              data-tip={t("dashboard.server.logs.tooltip.show_more", "Show More")}
              onClick={() => {
                if (selectLog() === localIdxLog) {
                  setSelectLog(0);
                } else {
                  setSelectLog(localIdxLog);
                }
              }}
            >
              <Show when={selectLog() === localIdxLog}>
                <i class="fas fa-chevron-up"></i>
              </Show>
              <Show when={selectLog() !== localIdxLog}>
                <i class="fas fa-chevron-down"></i>
              </Show>
            </div>
            <div class="tooltip tooltip-info" data-tip={t("dashboard.server.logs.tooltip.download", "Download")}>
              <a
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(logContentStr.join("\n"))}`}
                download={`${t("dashboard.server.logs.log_filename_prefix", "log-")}${props.createAt.toLocaleString()}.json`}
              >
                <i class="fas fa-download"></i>
              </a>
            </div>
          </div>
        </td>
      </tr>

      <Show when={selectLog() === localIdxLog}>
        <tr>
          <td colspan="4" class="p-0">
            <JsonViewer data={props} />
          </td>
        </tr>
      </Show>
    </>
  );
};

const ServerLogs: Component = () => {
  const { t } = useI18n();
  const [logs, { mutate: logsMutate }] = createResource("logs", fetchLogs);
  const [logsChannel, { mutate: logsChannelMutate }] = createResource("logsChannel", fetchLogsChannel);
  const [logHideIP, { mutate: logHideIPMutate }] = createResource("logHideIP", fetchLogHideIP);
  const [logFile, { mutate: logFileMutate }] = createResource("logFile", fetchLogFile);

  const [messages, setMessages] = createSignal([]);
  const [socket, setSocket] = createSignal(null);

  const sendLogChannel = async (channelID: string) => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs/channels", "POST", {
      channelID,
    });
    if (!res.ok) {
      return;
    }
    const screenshot = await res.json();
    logsChannelMutate(screenshot);
  };

  const removeLogChannel = async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs/channels", "DELETE");
    if (!res.ok) {
      return;
    }
    logsChannelMutate({});
    return {};
  };

  async function editLogHideIP(value: boolean) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_hide_ip", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        logHideIPMutate(data);
      });
  }

  async function editLogFile(value: boolean) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_include_file", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        logFileMutate(data);
      });
  }

  createEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket(
      getWSUrl("server_logs", [
        "guildID=" + getUrlWithActualParams(":guildID"),
        "serverID=" + getUrlWithActualParams(":serverID"),
      ]),
    );

    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onmessage = (event) => {
      const newMessage = event.data;
      setMessages((prevMessages) => [newMessage, ...prevMessages]);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(ws);

    // Cleanup on component unmount
    return () => {
      ws.close();
    };
  });

  return (
    <>
      <AdminChannelSelector id="select_channel_modal" callback={sendLogChannel} />

      <AdminPanel
        title={t("dashboard.server.logs.title.logs_channel", "Logs Channel")}
        description={t(
          "dashboard.server.logs.description.logs_channel",
          "Set a channel to sync the logs between your server and Discord.",
        )}
      >
        <div class="flex w-fit items-center">
          <span class="label-text mr-2">{t("dashboard.server.logs.label.chats_channels", "Chats Channels:")}</span>
          <Show
            when={!logsChannel.loading && logsChannel().channelID}
            fallback={<span class="label-text">{t("dashboard.server.logs.no_logs_channel", "No Logs Channel")}</span>}
          >
            <DiscordChannel channelID={logsChannel().channelID} />
          </Show>
        </div>
        <Show when={!logHideIP.loading}>
          <div class="flex w-fit items-center">
            <span class="label-text mr-2">{t("dashboard.server.logs.label.hide_ip", "Hide IP in discord logs:")}</span>
            <input
              type="checkbox"
              class="toggle toggle-md"
              checked={logHideIP() ? logHideIP().value : false}
              onChange={async (e) => {
                await editLogHideIP(e.currentTarget.checked);
              }}
            />
          </div>
        </Show>
        <Show when={!logFile.loading}>
          <div class="flex w-fit items-center">
            <span class="label-text mr-2">
              {t("dashboard.server.logs.label.attach_log_file", "Attach log information file:")}
            </span>
            <input
              type="checkbox"
              class="toggle toggle-md"
              checked={logFile() ? logFile().value : false}
              onChange={async (e) => {
                await editLogFile(e.currentTarget.checked);
              }}
            />
          </div>
        </Show>
        <div class="flex gap-4">
          <Show when={!logsChannel.loading && logsChannel().channelID}>
            <button
              class="btn btn-warning"
              onClick={async () => {
                await removeLogChannel();
              }}
            >
              {t("dashboard.server.logs.button.remove_channel", "Remove Channel")}
            </button>
          </Show>
          <button
            class="btn btn-primary"
            onclick="select_channel_modal.showModal()"
            onClick={() => guildChannelsRefetch()}
          >
            {t("dashboard.server.logs.button.select_channel", "Select Channel")}
          </button>
        </div>
      </AdminPanel>

      <AdminPanel
        title={t("dashboard.server.logs.title.logs", "Logs")}
        description={t("dashboard.server.logs.description.logs", "Watch the logs of your server.")}
        type="none"
        premium={t("dashboard.server.logs.premium", "Limited to 500 last logs for free users.")}
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th class="w-1/5">{t("dashboard.server.logs.table_headers.date", "Date")}</th>
              <th class="w-1/5">{t("dashboard.server.logs.table_headers.category", "Category")}</th>
              <th>{t("dashboard.server.logs.table_headers.log", "Log")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.logs.table_headers.actions", "Actions")}</th>
            </tr>
          </thead>
          <tbody>
            <For each={messages()}>
              {(msg) => {
                const parsedMsg = JSON.parse(msg);
                return <AddLogComponent data={parsedMsg.data} category={parsedMsg.type} createAt={new Date()} />;
              }}
            </For>
            <Show when={!logs.loading} fallback={<span class="loading loading-lg"></span>}>
              <For each={logs()}>
                {(log) => <AddLogComponent data={log.data} category={log.type} createAt={new Date(log.createdAt)} />}
              </For>
            </Show>
          </tbody>
        </table>
      </AdminPanel>
    </>
  );
};

export default ServerLogs;
