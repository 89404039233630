import { Component, createResource, createSignal, For, Show } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI, isPremium, NeedWebsocket, PremiumOnly } from "../../../../utils";
import { guildRoles } from "../GuildInformations";
import AdminModal from "../../../../components/AdminModal";
import { useI18n } from "../../../../i18n";
import DiscordRole from "../../../../components/discord/DiscordRole";

interface RoleSync {
  serverID: string;
  roleID: string;
  userGroup: string;
  enable: boolean;
}

const ServerRoles: Component = () => {
  const { t } = useI18n();
  const [pseudoDirection, { mutate: mutatePseudoDirection }] = createResource("pseudoDirection", async () => {
    return fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/sync_role_direction", "GET").then(
      async (res) => {
        if (!res.ok)
          throw new Error(
            t("dashboard.server.roles.error_occurred", "An error occurred while fetching the pseudo direction."),
          );
        return (await res.json()) || {};
      },
    );
  });

  const [selectRole, setSelectRole] = createSignal({} as RoleSync);

  const [rolesSync, { mutate: mutateRolesSync }] = createResource("rolesSync", async () => {
    return fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/roles", "GET").then(async (res) => {
      if (!res.ok)
        throw new Error(t("dashboard.server.roles.error_occurred", "An error occurred while fetching the roles sync."));
      return (await res.json()) || {};
    });
  });

  function getSelectorClassList(direction: string) {
    return !pseudoDirection.loading ? pseudoDirection().value === direction : false;
  }

  function updateSyncPseudoDirection(direction: string) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/sync_role_direction", "PUT", {
      value: direction,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            t("dashboard.server.roles.error_occurred", "An error occurred while updating the pseudo direction."),
          );
        }
      })
      .then((data) => {
        mutatePseudoDirection(data);
      });
  }

  async function addRole(roleID: string) {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/roles/${roleID}`, "POST")
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(t("dashboard.server.roles.error_occurred", "An error occurred while adding the role."));
        }
      })
      .then((data) => {
        mutateRolesSync((prev) => [...prev, data]);
      });
  }

  function editRole() {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/roles/${selectRole().roleID}`, "PUT", selectRole())
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(t("dashboard.server.roles.error_occurred", "An error occurred while editing the role."));
        }
      })
      .then((data) => {
        mutateRolesSync((prev) => prev.map((r) => (r.roleID === data.roleID ? data : r)));
      });
  }

  function deleteRole(roleID: string) {
    fetchAPI(`/users/:discordID/guilds/:guildID/servers/:serverID/roles/${roleID}`, "DELETE")
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(t("dashboard.server.roles.error_occurred", "An error occurred while deleting the role."));
        }
      })
      .then((data) => {
        mutateRolesSync((prev) => prev.filter((r) => r.roleID !== data.roleID));
      });
  }

  return (
    <>
      <NeedWebsocket />

      <AdminModal title={t("dashboard.server.roles.select_role", "Select Role")} id="select_role_modal">
        <Show
          when={!guildRoles.loading && !rolesSync.loading}
          fallback={<div>{t("dashboard.server.roles.loading", "Loading...")}</div>}
        >
          <div class="form-control">
            <select
              class="select select-bordered"
              onchange="select_role_modal.close()"
              onChange={async (e) => {
                await addRole(e.currentTarget.value);
              }}
            >
              <option value="0">{t("dashboard.server.roles.select_role", "Select a Role")}</option>
              <For each={guildRoles()}>
                {(role) => {
                  if (!rolesSync().find((v) => v.roleID === role.id)) {
                    return <option value={role.id}>{role.name}</option>;
                  }
                }}
              </For>
            </select>
          </div>
        </Show>
      </AdminModal>

      <AdminModal title={t("dashboard.server.roles.edit_role_sync", "Edit Role")} id="edit_role_modal">
        <Show when={!guildRoles.loading && !rolesSync.loading}>
          <div class="form-control">
            <label class="label">
              <span class="label-text">{t("dashboard.server.roles.role_name", "Discord Role")}</span>
            </label>
            <select class="select select-bordered" disabled>
              <option selected>{guildRoles().find((r) => r.id === selectRole().roleID)?.name}</option>
            </select>
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">{t("dashboard.server.roles.select_user_group", "User Group")}</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              value={selectRole().userGroup}
              onInput={(e) => {
                selectRole().userGroup = e.currentTarget.value;
              }}
            />
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">{t("dashboard.server.roles.enable_sync", "Active")}</span>
            </label>
            <select
              class="select select-bordered"
              value={selectRole().enable ? "true" : "false"}
              onChange={(e) => {
                selectRole().enable = e.currentTarget.value === "true";
              }}
            >
              <option value="true">{t("dashboard.server.roles.yes", "Yes")}</option>
              <option value="false">{t("dashboard.server.roles.no", "No")}</option>
            </select>
          </div>
          <button
            class="btn btn-primary mt-2"
            onclick="edit_role_modal.close()"
            onClick={async () => {
              editRole();
            }}
          >
            {t("dashboard.server.roles.save_changes", "Save")}
          </button>
        </Show>
      </AdminModal>

      <AdminPanel
        title={t("dashboard.server.roles.title", "Roles")}
        description={t(
          "dashboard.server.roles.description",
          "Define the roles that are synchronized with your Discord server.",
        )}
        premium={t(
          "dashboard.server.roles.premium_feature",
          "Synchronization limited to 'Gmod to Discord' for free users.",
        )}
      >
        <div class="flex w-fit items-center">
          <span class="label-text mr-2 text-nowrap">
            {t("dashboard.server.roles.sync_direction", "Roles Synchronization Direction") + " : "}
          </span>
          <select
            class="select select-bordered w-full max-w-xs"
            onChange={(e) => {
              updateSyncPseudoDirection(e.currentTarget.value);
            }}
          >
            <option value="discord-to-gmod" selected={getSelectorClassList("discord-to-gmod")} disabled={!isPremium()}>
              {t("dashboard.server.roles.discord_to_server", "From Discord to Gmod")} <PremiumOnly />
            </option>
            <option value="gmod-to-discord" selected={getSelectorClassList("gmod-to-discord")}>
              {t("dashboard.server.roles.server_to_discord", "From Gmod to Discord")}
            </option>
            <option value="both" selected={getSelectorClassList("both")} disabled={!isPremium()}>
              {t("dashboard.server.roles.both_directions", "Both Ways")} <PremiumOnly />
            </option>
          </select>
        </div>
      </AdminPanel>

      <AdminPanel
        title={t("dashboard.server.roles.role_sync", "Roles Syncronized")}
        description={t(
          "dashboard.server.roles.role_sync_description",
          "Define the roles that are synchronized with your Discord server.",
        )}
        type="none"
        premium={t("dashboard.server.roles.premium_feature_3", "Limited to 3 synchronized roles for free users.", 3)}
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>{t("dashboard.server.roles.role_name", "Discord Role")}</th>
              <th>{t("dashboard.server.roles.user_group", "User Group")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.roles.status", "Active")}</th>
              <th class="w-1/6 text-center">{t("dashboard.server.roles.actions", "Actions")}</th>
            </tr>
          </thead>
          <tbody>
            <Show
              when={!rolesSync.loading}
              fallback={
                <div class="flex justify-center h-36">
                  <div class="loading loading-spinner loading-lg"></div>
                </div>
              }
            >
              <For each={rolesSync()}>
                {(roleSync) => (
                  <tr>
                    <td>
                      <DiscordRole role={guildRoles().find((r) => r.id === roleSync.roleID)} />
                    </td>
                    <td>{roleSync.userGroup}</td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        {roleSync.enable ? (
                          <i class="fas fa-check text-green"></i>
                        ) : (
                          <i class="fas fa-times text-red"></i>
                        )}
                      </div>
                    </td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        <div class="tooltip tooltip-info" data-tip={t("dashboard.server.roles.edit_role_sync", "Edit")}>
                          <i
                            class="hover:cursor-pointer fas fa-edit"
                            onclick="edit_role_modal.showModal()"
                            onClick={() => setSelectRole(roleSync)}
                          ></i>
                        </div>
                        <div
                          class="tooltip tooltip-error"
                          data-tip={t("dashboard.server.roles.delete_role_sync", "Delete")}
                        >
                          <i
                            class="hover:cursor-pointer fas fa-trash text-red"
                            onClick={() => deleteRole(roleSync.roleID)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </Show>
          </tbody>
        </table>

        <div class="flex gap-4 p-4">
          <button
            class="btn btn-primary"
            onclick="select_role_modal.showModal()"
            disabled={!isPremium() && (!rolesSync() || rolesSync().length >= 3)}
          >
            {t("dashboard.server.roles.add_role_sync", "Add Role")}
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerRoles;
